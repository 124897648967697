<template>
  <div class="aps-wrap flex-column">

    <div class="aps-head">
      <div class="title1 mb-8">{{$t('applicants.applicants')}}</div>
      <MiniTabs :tabs="tabs" :tabName="preOpenData.tabName" @openTab="openTab"></MiniTabs>
    </div>

    <StudentTableWithCard
        v-if="currentTab.id === 1"
        :key="1"
        :options="tabs[0]"
        :preOpen="preOpenData"
    ></StudentTableWithCard>

    <StudentTableWithCard
        v-else-if="currentTab.id === 2"
        :key="2"
        :options="tabs[1]"
        :preOpen="preOpenData"
    ></StudentTableWithCard>

   <StudentTableWithCard
       v-else-if="currentTab.id === 3"
       :key="3"
       :options="tabs[2]"
       :preOpen="preOpenData"
   ></StudentTableWithCard>

   <StudentTableWithCard
       v-else-if="currentTab.id === 4"
       :key="4"
       :options="tabs[3]"
       :preOpen="preOpenData"
   ></StudentTableWithCard>

    <StudentTableWithCard
        v-else-if="currentTab.id === 5"
        :key="5"
        :options="tabs[5]"
        :preOpen="preOpenData"
    ></StudentTableWithCard>

<!--    todo: no use index in this-->
    <StudentTableWithCard
        v-else-if="currentTab.id === 6"
        :key="6"
        :options="tabs[6]"
        :preOpen="preOpenData"
    ></StudentTableWithCard>

  </div>
</template>

<script>
import MiniTabs from "@/components/students/card/MiniTabs";
import StudentTableWithCard from "@/components/students/StudentTableWithCard";
import Review from "@/components/students/tables/applicants/Review";
import Interview from "@/components/students/tables/applicants/Interview";
import Payment from "@/components/students/tables/applicants/Payment";
import Enrollment from "@/components/students/tables/applicants/Enrollment";
import Rejected from "@/components/students/tables/applicants/Rejected";
import PreSeeds from "@/components/students/tables/applicants/PreSeeds";
import {paramsForServer} from "feathers-hooks-common";

export default {
  name: "aps.vue",
  components: {
    PreSeeds,
    Rejected,
    Enrollment,
    Payment,
    Interview,
    Review,
    StudentTableWithCard,
    MiniTabs
  },
  data() {
    return {
      tabs: [
        {
          id: 1,
          name: 'review',
          title: 'Review',
          description: 'Application review',
          statusList: ['review_started'],
          cardTabs: ['applicant_info'],
          count: 0,
          component: Review,
        },
        {
          id: 2,
          name: 'interview',
          title: 'Interview',
          description: 'Interview',
          statusList: ['interview_booking', 'interview_booked', 'interview_attended', 'interview_missed'],
          cardTabs: ['applicant_info', 'evaluation'],
          count: 0,
          component: Interview,
        },
        {
          id: 3,
          name: 'payment',
          title: 'Payment',
          description: 'Payment',
          statusList: [
            'choosing_payment',
            'payment_by_card',
            'payment_by_wire_transfer',
            'payment_confirming',
          ],
          cardTabs: ['payment_info'],
          count: 0,
          component: Payment,
        },
        {
          id: 4,
          name: 'enrollment',
          title: 'Enrollment',
          description: 'Enrollment',
          statusList: ['payment_completed'],
          cardTabs: ['applicant_info', 'evaluation', 'payment_info','payment_history'],
          count: 0,
          component: Enrollment,
        },
        {name: '|'},
        {
          id: 5,
          name: 'rejected',
          title: 'Rejected',
          description: 'Rejected applicants',
          statusList: [
            'review_rejected',
            'interview_rejected',
            'payment_rejected',
          ],
          cardTabs: ['applicant_info', 'evaluation', 'payment_info'],
          count: 0,
          component: Rejected,
        },
        {
          id: 6,
          name: 'preseeds',
          title: 'Pre-seeds',
          description: 'Pre-seeds',
          params: {
            schema: 'pre-seeds'
          },
          cardTabs: ['applicant_info', 'progress'],
          count: 0,
          service: 'users',
          component: PreSeeds,
        },
      ],
      currentTab: {},
      preOpenData: {},
    }
  },
  methods: {
    openTab(tab) {
      this.currentTab = tab;
    },
    async reloadCounters() {
      const data = await this.$store.dispatch(
          'student-statuses/find',
          paramsForServer({
            schema: 'amount'
          })
      );
      // response from find should always be array,
      // so we use entries, for transporting object
      const counters = Object.fromEntries(data);
      this.$log(counters);
      for (const tab of this.tabs) {
        tab.count = counters[tab.name]
      }
    },
  },
  created() {
    this.preOpenData = {...this.$route.query};
    this.$log('PRE OPEN DATA:', this.preOpenData);
  },
  async mounted() {
    await this.reloadCounters();
  },
}
</script>

<style scoped>

</style>
