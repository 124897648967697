<template>
  <table class="ui-table mb-16">
    <tr>
      <th>
        <div class="star-pad">
          <UiStar />
        </div>
      </th>
      <th>
        <div>
          <SortIcon name="user.firstName" :ds="ds">{{
            $t('Interview.applicant')
          }}</SortIcon>
        </div>
      </th>
      <th>
        <div>
          <SortIcon name="lastStatus" :ds="ds">{{
            $t('Interview.booking_status')
          }}</SortIcon>
        </div>
      </th>
      <th>
        <div>
          <SortIcon name="interviewDate" :ds="ds">{{
            $t('Interview.interview_date')
          }}</SortIcon>
        </div>
      </th>

      <th>
        <div>
          <SortIcon name="item.missedCount" :ds="ds">{{
            $t('Interview.missed_interviews')
          }}</SortIcon>
        </div>
      </th>
      <th>
        <div>{{ $t('Interview.shift_date') }}</div>
      </th>
      <th>
        <div>{{ $t('Interview.cv') }}</div>
      </th>
    </tr>
    <tr
      v-for="item in items"
      :key="item.id"
      @click="$emit('select', item)"
      class="hoverable"
      :class="{ hover: item.id === currentItemId }"
    >
      <td>
        <div class="table-cell star-pad">
          <UiStar :user="item"></UiStar>
        </div>
      </td>
      <td>
        <div class="table-cell nowrap">
          <UiAvatar
            class="user-avatar"
            :src="getAvatarUrl(item?.user?.photo?.key)"
          ></UiAvatar>
          <span>
            {{ item.user.firstName }}&nbsp;{{ item.user.middleName }}&nbsp;{{
              item.user.lastName
            }}
            <div class="mini-date nowrap">
              <div class="mini-status">
                <UiStatus :student="item"></UiStatus>
              </div>
              {{ $dayjs(item.createdAt).format('DD/MM/YYYY') }}
            </div>
          </span>
        </div>
      </td>
      <td>
        <UiStatus :student="item">{{ $t('Interview.booked') }}</UiStatus>
      </td>
      <td>{{ getStudentInterviewDate(item) }}</td>
      <td>{{ item.missedCount }}</td>
      <td class="nowrap">
        {{ $dayjs(item.interviewBookingDate).format('DD/MM/YYYY') }}
      </td>
      <td>
        <div class="cv" v-if="item?.cv?.key" @click.stop="downloadCV(item)"></div>
        <div v-else>n/a</div>
      </td>
    </tr>
  </table>
</template>

<script>
import config from '@/config'
import UiAvatar from '@/components/ui/UiAvatar'
import UiStar from '@/components/ui/UiStar'
import UiStatus from '@/components/ui/UiStatus'
import SortIcon from '@/components/SortIcon'

export default {
  name: 'Interview',
  components: { SortIcon, UiStatus, UiStar, UiAvatar },
  props: {
    items: {
      type: Array,
      required: true,
    },
    currentItemId: {},
    ds: {
      type: Object,
      required: true,
    },
  },
  emits: ['select'],
  methods: {
    getAvatarUrl(key) {
      if (key)
        return `${config.backendUrl}/${key}?token=${localStorage['feathers-jwt']}`
      else return require('@/assets/images/no-avatar.jpg')
    },
    downloadCV(student) {
      const url = `${config.backendUrl}/${student.cv.key}?token=${localStorage['feathers-jwt']}`
      this.$log(url)
      window.open(url)
    },
    getStudentInterviewDate(student) {
      const startDate = student.lastInterview?.timeslot?.startDate
      return startDate
        ? this.$dayjs(startDate).format('DD/MM/YYYY, HH:mm')
        : '-'
    },
  },
}
</script>

<style scoped></style>
