<template>
  <table class="ui-table mb-16">

    <tr>

      <th>
        <div class="star-pad">
          <UiStar></UiStar>
        </div>
      </th>

      <th>
        <div><SortIcon name="firstName" :ds="ds">{{$t('Enrollment.applicant')}}</SortIcon></div>
      </th>
      <th>
        <div>{{$t('Enrollment.shift_date')}}</div>
      </th>
      <th>
        <div>{{$t('Enrollment.paid_amount')}}</div>
      </th>
      <th>
        <div>{{$t('Enrollment.group')}}</div>
      </th>
      <th >
        <div>{{$t('Enrollment.german_level')}}</div>
      </th>
    </tr>

    <tr
        v-for="item in items"
        :key="item.id"
        @click="$emit('select', item)"
        class="hoverable" :class="{hover: item.id === currentItemId}">

      <td>
        <div class="table-cell star-pad">
          <UiStar :user="item"></UiStar>
        </div>
      </td>

      <td>
        <div class="table-cell nowrap">
          <UiAvatar class="user-avatar" :src="getAvatarUrl(item?.user?.photo?.key)"></UiAvatar>
          <span>
           {{ item.user.firstName }}&nbsp;{{ item.user.middleName }}&nbsp;{{ item.user.lastName }}
            <div class="mini-date">{{ $dayjs(item.createdAt).format('DD/MM/YYYY') }}</div>
          </span>
        </div>
      </td>
      <td>
        <div>{{ $dayjs(item.lastStatus.createdAt).format('DD/MM/YYYY') }}</div>
      </td>
      <td>
        <div>{{$t('Enrollment.full')}}</div>
      </td>
      <td>
        <div>23/11/2020, 12:00 – 15:00</div>
      </td>
      <td>
        <div>{{$t('Enrollment.a')}}</div>
      </td>
    </tr>
  </table>
</template>

<script>
import config from "@/config";
import UiAvatar from "@/components/ui/UiAvatar";
import UiStar from "@/components/ui/UiStar";
import UiStatus from "@/components/ui/UiStatus";
import SortIcon from "@/components/SortIcon";

export default {
  name: "Enrollment",
  components: {SortIcon, UiStatus, UiStar, UiAvatar},
  props: {
    items: {
      type: Array,
      required: true,
    },
    currentItemId: {},
    ds: {
      type: Object,
      required: true,
    }
  },
  emits: ['select'],
  methods: {
    getAvatarUrl(key) {
      if (key) return `${config.backendUrl}/${key}?token=${localStorage['feathers-jwt']}`;
      else return require('@/assets/images/no-avatar.jpg')
    },
    downloadCV(student) {
      const url = `${config.backendUrl}/${student.cv.key}?token=${localStorage['feathers-jwt']}`
      this.$log(url);
      window.open(url);
    },
  }
}
</script>

<style scoped>

</style>
