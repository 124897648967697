<template>
  <table class="ui-table mb-16">
    <tr>
      <th>
        <div class="star-pad">
          <UiStar></UiStar>
        </div>
      </th>
      <th>
        <div><SortIcon name="email" :ds="ds">{{$t('PreSeeds.applicant')}}</SortIcon></div>
      </th>
      <th>
        <div>{{$t('PreSeeds.applying_date')}}</div>
      </th>
      <th>
        <div>{{$t('PreSeeds.status')}}</div>
      </th>
      <th>
        <div>{{$t('PreSeeds.last_update')}}</div>
      </th>
    </tr>

    <tr
        v-for="item in items"
        :key="item.id"
        @click="$emit('select', item)"
        class="hoverable" :class="{hover: item.id === currentItemId}">

      <td>
        <div class="table-cell star-pad">
          <UiStar :user="item"></UiStar>
        </div>
      </td>

      <td>
        <div class="table-cell nowrap">
          <UiAvatar class="user-avatar" :src="getAvatarUrl(item?.user?.photo?.key)"></UiAvatar>
          <span>
            <!-- {{ item }} Hey -->
            <span v-if="item?.root?.firstName">{{ item?.root?.firstName }}&nbsp;{{ item?.root?.middleName }}&nbsp;{{ item?.root?.lastName }}</span>
            <span v-else>{{ item.email || item.root.email }}</span>
            <div class="mini-date">{{ $dayjs(item.createdAt).format('DD/MM/YYYY') }}</div>
          </span>
        </div>
      </td>
      <td>
        <div class="nowrap">{{ $dayjs(item.createdAt).format('DD/MM/YYYY') }}</div>
      </td>
      <td>
        <div>{{ item?.detail?.comments?.preSeeds ? item?.detail?.comments?.preSeeds : '-' }}</div>
      </td>
      <td>
        <div>{{ preSeedUpdatedAt(item) }}</div>
      </td>
    </tr>
  </table>
</template>

<script>
import config from "@/config";
import UiAvatar from "@/components/ui/UiAvatar";
import UiStar from "@/components/ui/UiStar";
import SortIcon from "@/components/SortIcon";

export default {
  name: "PreSeeds",
  components: {SortIcon, UiStar, UiAvatar},
  props: {
    items: {
      type: Array,
      required: true,
    },
    currentItemId: {},
    ds: {
      type: Object,
      required: true,
    }
  },
  emits: ['select'],
  methods: {
    getAvatarUrl(key) {
      if (key) return `${config.backendUrl}/${key}?token=${localStorage['feathers-jwt']}`;
      else return require('@/assets/images/no-avatar.jpg')
    },
    preSeedUpdatedAt(student) {
      const dayjs = this.$dayjs(student.updatedAt);
      const daysAgo = -dayjs.diff(new Date(), 'day');
      return daysAgo + ' days ago';
    },
  }
}
</script>

<style scoped>

</style>